import { IAlertService } from '../../libs/ialert/ialerts.service'
import { ApiService } from '../../services/api.service'
import { Component, OnInit, ViewChild } from '@angular/core'
import { DataService } from './data.service'
import * as moment from 'moment'
import { ConstantsService } from 'src/app/services/constants.service'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { base64ToFile } from 'ngx-image-cropper'
import { FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
    selector: 'app-driver-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    inspection15: Number = 1500
    balance: any
    inspection1: Number = 125
    packageAmount: Number = 0
    editedPackageAmount: Number = this.packageAmount
    isEditing: boolean = false
    isPack2Active: boolean = false
    isPack1Active: boolean = false
    mt = moment
    month = ''
    year: any = 2020
    modalRef: BsModalRef
    modalRefPayNow: BsModalRef
    modalRefRequest: BsModalRef
    todayDate = new Date()
    fetching = true
    visitorFetching = 'inprogress'
    projectCount: any
    totalDownloads = 0
    storedData: any = {}
    dateFormat: any
    monthlySalesData: Array<any> = []
    monthlyTotalAmount = 0
    activeMenu = 'business'
    dataStatus = 'none'
    dashboardData: any = []
    topSallingProduct: any = []
    topSallingCategoires: any = []
    recentlyAddedProduct: any = []
    status = false
    confirmPaymentDetails: any = {}
    paymentDetail: any = {}
    confirmPayment: boolean = false
    paymentFG: FormGroup
    name: any
    filters: any = {
        startDate: '',
        endDate: '',
        page: 1
    }

    loaderOptions: LoaderOptionsObject = ConstantsService.SKELETON_LOADER_DEFAULT_CONFIG

    constructor(
        public api: ApiService,
        public ds: DataService,
        private ms: BsModalService,
        public cs: ConstantsService,
        public alert: IAlertService,
        public router: Router,
        public sanitized: DomSanitizer
    ) {
        this.dateFormat = cs.DATE_TIME_FORMAT.SHORT_DATE
        this.name = api.user.parallel.name
    }

    ngOnInit() {
        this.status = false
        this.paymentFG = new FormGroup({
            token: new FormControl('', Validators.required)
        })
        /* this.ds.dashboardData().subscribe((resp: any) => {
            if (resp.success === true) {
                this.dataStatus = 'done'
                this.storedData = resp.data
                this.status = true
            }
        })*/
        // const params = { ...this.filters }
        // this.ds.getMyWallet(params).subscribe((resp) => {
        //     this.balance = resp.data.currentBalance
        // })

        this.api.balance.subscribe((r:any)=>{
            this.balance = r
        })


    }

    openModal(formModal: any, id: number, index: number) {
        // this.modalTitle = 'Add New Mentor'
        // //this.dataForm.controls.status.setValue('Active')
        // this.selectedIndex = index
        // if (index > -1) {
        //     this.modalTitle = 'Edit Mentor'
        //     this.selectedId = id
        //     this.selectedIndex = index
        // }
        this.modalRef = this.ms.show(formModal, {
            class: 'modal-sm modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    logOut(): void {
        this.api.logOutSession().subscribe((resp: any) => {
            const check = this.api.logOut()
            if (check) {
                location.reload()
            }
        })
    }

    openStudents() {
        this.router.navigate(['/admin/students'])
    }

    openTeams() {
        this.router.navigate(['/admin/teams'])
    }

    openTransactions() {
        this.router.navigate(['/admin/transactions'])
    }

    forEachInspection() {
        this.isPack2Active = false
        this.isPack1Active = true
        this.packageAmount = 0
    }
    forManyInspection() {
        this.isPack1Active = false
        this.isPack2Active = true
        this.packageAmount = this.paymentDetail.fifteenDayInspectionAmount
    }
    clearAmount() {
        this.isPack1Active = false
        this.isPack2Active = false
        this.packageAmount = 0
        setTimeout(() => {
            this.confirmPayment = false
        }, 500)
    }
    enterEditMode() {
        this.editedPackageAmount = this.packageAmount
        this.isEditing = true
    }
    saveEditedAmount() {
        if (this.packageAmount !== this.inspection15) {
            this.packageAmount = this.editedPackageAmount
            this.isEditing = false
        }
        if (this.packageAmount === this.inspection15) {
            this.isPack2Active = true
            this.isPack1Active = false
            this.packageAmount = this.inspection15
        }
        if (this.packageAmount === 0) {
            this.isPack1Active = false
            this.isPack2Active = false
        }
    }

    openRequetDetailModal(formModal: any) {
        this.modalRef = this.ms.show(formModal, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    requestPackageDetail(packageDetail: any) {
        this.openRequetDetailModal(packageDetail)
    }

    openRequestPayNowModal(template: any) {
        this.fetching = true
        this.modalRefPayNow = this.ms.show(template, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
        this.ds.getPaymentDetail().subscribe((resp) => {
            this.fetching = false
            if (resp.success) {
                if (resp.data != null) {
                    this.paymentDetail = resp.data
                    this.paymentDetail.perDayAmount = this.paymentDetail.fifteenDayInspectionAmount / 15
                    if (this.paymentDetail.perDayAmount < this.paymentDetail.singleDayInspectionAmount) {
                        this.paymentDetail.savingPerDay = this.paymentDetail.singleDayInspectionAmount - this.paymentDetail.perDayAmount
                    }
                }
                else {

                }
            }
            else {

            }
        })
    }

    openRequestModal(template: any) {
        this.modalRefRequest = this.ms.show(template, {
            class: 'modal-lg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    closeModals1() {
        if (this.modalRefPayNow) {
            this.modalRefPayNow.hide()
        }

    }

    closeModals2() {
        if (this.modalRefRequest) {
            this.modalRefRequest.hide()
        }
    }
    payRequest() {
        this.dataStatus = 'fetching'
        let params = {
            amount: this.packageAmount,
            package: true
        }
        if (this.packageAmount !== this.paymentDetail.fifteenDayInspectionAmount) {
            params.package = false
        }
        this.ds.payRequest(params).subscribe((resp: any) => {
            if (resp.success) {
                this.dataStatus = 'done'
                this.confirmPaymentDetails = resp.data
                this.confirmPaymentDetails.url = atob(resp.data.url)
                this.paymentFG.controls.token.setValue(resp.data.token)
                this.confirmPayment = true

            }
        })
    }

    submitForm() {
        const form: any = window.document.getElementById("formAuthorizeNetTestPage")
        form.submit()
    }
}
