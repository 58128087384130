<!-- <h1>Dashboard</h1> -->
<div class="dashboard-main-container">
    <section class="dashboard-section">
        <div class="dashboard-container">
            <div class="dash-text">
                <p class="text-name">
                    Hi <span class="dash-name">{{ name }}!</span>
                </p>
                <p class="text-balance" style="margin-bottom: 10px; font-size: 18px">
                    Balance: <span *ngIf="balance >= 0">${{ balance | number: '1.2-2' }}</span>
                    <span *ngIf="balance < 0">-${{ balance * -1 | number: '1.2-2' }}</span>
                </p>
                <div class="btns">
                    <button class="cg success" style="width: fit-content; margin-bottom: 10px; margin-top: 0"
                        (click)="openRequestPayNowModal(requestPayNowTemplate)">Add to Balance</button>
                    <!-- <button class="cg warning" style="width: fit-content">Buy 15 inspections for 1500$</button> -->
                </div>
                <!-- <p class="text-date">
                    Last Login:
                    <ng-container *ngIf="api.user.lastLogin === null; else lastLoginTemp">
                        <span class="dash-date" style="letter-spacing: 1px">First Time Login</span>
                    </ng-container>
                    <ng-template #lastLoginTemp>
                        <span class="dash-date">{{ api.user.lastLogin | date: 'MMMM d, y AT hh:mm a' }}</span>
                    </ng-template>
                </p> -->
            </div>
            <div class="dash-img-wrapper"><img src="/assets/images/logo.png" alt="" /></div>
        </div>
    </section>

    <h1 class="text-insight">INSIGHTS</h1>
    <!--<div class="row">
        <div class="col-md-4 col-sm-4 d-flex insight-container pt-2 pb-2" (click)="openStudents()">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <ng-container *ngIf="dataStatus === 'fetching'">
                    <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
                </ng-container>
                <span *ngIf="dataStatus === 'done'">{{ storedData.totalStudents ? storedData.totalStudents : 0 }}</span>
                <p class="user-data-titles">Total Students</p>
            </div>
        </div>

        <div class="col-md-4 col-sm-4 d-flex insight-container pt-2 pb-2" (click)="openTeams()">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <ng-container *ngIf="dataStatus === 'fetching'">
                    <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
                </ng-container>
                <span *ngIf="dataStatus === 'done'">{{ storedData.totalTeams ? storedData.totalTeams : 0 }}</span>
                <p class="user-data-titles">Total Teams</p>
            </div>
        </div>
        <div class="col-md-4 col-sm-4 d-flex insight-container pt-2 pb-2" (click)="openTransactions()">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <ng-container *ngIf="dataStatus === 'fetching'">
                    <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
                </ng-container>
                <ng-container *ngIf="status === true; else totalAmountOwed">
                    <span *ngIf="storedData.totalAmountOwed > 0">-${{ storedData.totalAmountOwed | number: '1.2-2' }}</span>
                    <span *ngIf="storedData.totalAmountOwed <= 0">${{ storedData.totalAmountOwed * -1 | number: '1.2-2' }}</span>
                </ng-container>
                <ng-template #totalAmountOwed><span *ngIf="dataStatus === 'done'">$0.00</span></ng-template>
                <p class="user-data-titles">Total Amount Owed</p>
            </div>
        </div>
    </div>-->
    &nbsp;
</div>


<ng-template #requestPayNowTemplate>
    <ng-container>
    </ng-container>
    <div class="modal-header" [ngStyle]="{ padding: dataStatus == 'fetching' ? '0' : '50px' }"
        style="background: rgba(221, 221, 221, 1)" *ngIf="dataStatus === 'fetching' || fetching">
        <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
    </div>
    <div class="modal-body" style="background: rgba(221, 221, 221, 1)" *ngIf="!fetching">
        <div class="container-package" *ngIf="!confirmPayment">
            <div class="packages">
                <span class="borders"></span>
                <div class="pack1" [ngClass]="{ active: isPack2Active }" (click)="forManyInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text">
                        <div>
                            <h2>15 Inspections In</h2>
                            <h1>${{ paymentDetail.fifteenDayInspectionAmount | number : '1.2-2' }}</h1>
                        </div>
                        <div>
                            <h3>Per inspection ${{paymentDetail.perDayAmount | number : '1.2-2'}}</h3>
                            <h3 *ngIf="paymentDetail.savingPerDay">${{paymentDetail.savingPerDay | number : '1.2-2'}}
                                per
                                inspection saving
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="pack2" [ngClass]="{ active: isPack1Active }" (click)="forEachInspection()">
                    <img src="/assets/images/rectangle.png" alt="" class="package-img" />
                    <div class="text" style="padding: 10px 20px; text-align: center">
                        <h2>Add balance to your wallet</h2>
                        <h3>* Pay for each inspection at ${{paymentDetail.singleDayInspectionAmount}}, 15th is free</h3>
                        <!-- <div>
                            <h2>Pay for each inspection at</h2>
                            <h1>{{ inspection1 }}$</h1>
                        </div>
                        <div>
                            <h3>15th is free</h3>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="amount" *ngIf="isPack1Active || isPack2Active">
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap" *ngIf="isPack1Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <input style="border: 0; outline: 0; width: 80px; font-size: 24px" type="number"
                        [(ngModel)]="packageAmount" />
                </div>
                <div style="display: flex; gap: 1.5rem; align-items: center; flex-wrap: wrap"
                    *ngIf="packageAmount == paymentDetail.fifteenDayInspectionAmount && isPack2Active">
                    <h2 style="font-size: 28px">Amount:</h2>
                    <h1>{{ packageAmount }}$</h1>
                </div>
                <button [disabled]="packageAmount != 0 && dataStatus== 'fetching' " class="pac-pay" type="button"
                    (click)="payRequest()" *ngIf="packageAmount !== 0 && packageAmount != null">Pay Now</button>
            </div>
            <div class="amount" *ngIf="packageAmount <= 0 && !isPack1Active">
                <h2>Please Select Option</h2>
            </div>
        </div>

        <div class="payment-confirmation" *ngIf="confirmPayment">
            <p class="heading">Are you sure to pay ${{packageAmount}}?</p>
            <form [formGroup]="paymentFG" method="post" [action]="this.confirmPaymentDetails.url"
                id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage" (ngSubmit)="submitForm()">
                <input type="hidden" name="token" formControlName="token" />
                Continue to Authorize.net to Payment Page
                <button type="submit" class="cg success" style="margin-left: 20px;">Continue to next page</button>
            </form>
        </div>
        <div class="modal-footer" style="background: rgba(221, 221, 221, 1)">
            <button type="button" class="cg primary ms-2" (click)="closeModals1(); clearAmount()">Cancel</button>
        </div>
    </div>
</ng-template>