import { apis } from 'src/environments/environment'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { Router } from '@angular/router'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/parallel`
    activeMenu = 'business'

    constructor(public http: HttpClient, private router: Router) { }

    getMyWallet(params: any): Observable<any> {
        const url = `${this.baseUrl}/my-wallet`
        return this.http.get<any>(url, { params })
    }
    getPaymentDetail(): Observable<any> {
        const url = `${this.baseUrl}/payment-setting/detail`
        return this.http.get<any>(url)
    }

    dashboardData(): Observable<any> {
        const url = `${apis.baseUrl}/admin/dashboard/detail`

        return this.http.get<any>(url)
    }
    payRequest(params: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/my-wallet/pay`, params)
    }
    afterPayresquest(params: any): Observable<any> {
        return this.http.post(`https://test.authorize.net/payment/payment`, params)
    }
}
