import { LazyLoadImageModule } from 'ng-lazyload-image'
import { ParallelSharedModule } from './../parallel-shared/parallel-shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/website/shared/shared.module'
import { DataService } from './data.service'
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { DashboardComponent } from './dashboard.component'
import { BsModalService } from 'ngx-bootstrap/modal'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'

@NgModule({
    imports: [
        SharedModule,
        ParallelSharedModule,
        ReactiveFormsModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        LazyLoadImageModule,
        RouterModule.forChild([
            {
                path: '',
                component: DashboardComponent
            }
        ])
    ],
    declarations: [DashboardComponent],
    providers: [DataService, BsModalService]
})
export class DashboardModule {}
